import React from 'react'
import { useLangContext } from '../../context/lang.context'
import { globals,colors, s, sTextGradient } from '../../style'
import Main from '../../components/Main'

import CheckCircleIcon from '../../assets/icons/CheckCircleIcon'
import CareerOnboardingIcon from '../../assets/icons/CareerOnboardingIcon'
import CareerMotivationIcon from '../../assets/icons/CareerMotivationIcon'

const icons = {
  check: <CheckCircleIcon />,
  onboarding: <CareerOnboardingIcon />,
  motivation: <CareerMotivationIcon />,
}

const content = {
  title: {
    pl: 'Zobacz co u nas',
    en: '',
  },
  links: [
    {
      title: {
        pl: 'Linkedin',
        en: 'Linkedin',
      },
      link: 'https://www.linkedin.com/company/omidaseaandair',
      icon: 'check',
    },
    {
      title: {
        pl: 'Facebook',
        en: 'Facebook',
      },
      link: 'https://www.facebook.com/omidaseaandair/',
      icon: 'onboarding',
    },
    {
      title: {
        pl: 'YouTube',
        en: 'YouTube',
      },
      link: 'https://www.youtube.com/channel/UCeaemAUE3MdbHoFRD95DgfA',
      icon: 'motivation',
    },
  ],
}

const SocialsSections = () => {
  const { lang } = useLangContext()

  return (
  <Main h={2} title={content.title[lang]}>
    <div css={sFeatures}>
      {content.links.map(({ title, link, icon }, id) => (
        <SocialLink key={id} link={link} title={title} icon={icon} />
      ))}
    </div>
  </Main>
  )
}

const SocialLink = ({ title, link, icon }) => {
  const { lang } = useLangContext()
  return (
    <a href={link} css={sFeature}>
      <div css={sIconOuter}>
        <div css={sIconInner}>{icon ? icons[icon] : <CheckCircleIcon />}</div>
      </div>
      <div>
        <h3 css={[sTitle, sTextGradient]}>
          {typeof title === 'object' ? title[lang] : title}
        </h3>
      </div>
    </a>
  )
}

const sFeatures = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '8rem',
  marginTop: '4rem',
  [s.sm_down]: {
    gridTemplateColumns: '1fr',
    gap: '4rem',
    marginTop: '4rem',
  },
}

const sFeature = {
  display: 'flex',
  alignItems: 'center'
}

const sIconOuter = {
  width: 64,
  height: 64,
  borderRadius: 16,
  background: `linear-gradient(to bottom right, ${colors.brandLight}, ${colors.brandDark})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '1.5rem',
  flexShrink: 0,
}

const sIconInner = {
  width: 56,
  height: 56,
  borderRadius: 12,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.brandDark,
  svg: {
    width: 32,
    height: 32,
  },
}

const sTitle = {
  lineHeight: '1.35em',
  fontSize: '1.25rem',
  textTransform: 'uppercase',
  fontWeight: 700,
  color: colors.brandDark,
  [s.sm_down]: {
    fontSize: '1.25rem',
  },
}

export default SocialsSections