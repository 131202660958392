import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { useLangContext } from '../../context/lang.context'
import { useMatchMedia } from '../../utils/useMatchMedia'
import { Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import Main from '../../components/Main'
import Video from '../../components/Video'
import { colors, s, alpha } from '../../style'

const content = {
  subtitle: 'Sea & Air Video',
  title: {
    pl: 'Zobacz video naszego zespołu',
    en: '',
  },
  desc: {
    pl: 'Dowiedz się, dlaczego warto być częścią Omida Sea And Air S.A.',
    en: '',
  },
  video: 'https://www.youtube.com/embed/jSADZH9OG6M&t=8s',
  videos: [
    'https://www.youtube.com/embed/OGDIYnl-VQY',
    'https://www.youtube.com/embed/hH0mLybuVRg',
    'https://www.youtube.com/embed/BY5nxh7clTk',
  ],
}

const updatedContent = {
  ...content,
  videos: [content.video, ...content.videos],
}

const VideosSection = () => {
  const { lang } = useLangContext()
  const isDesktopResolution = useMatchMedia('(min-width:960px)', true)
  return (
    <Main
      h={2}
      title={content.title[lang]}
      desc={content.desc[lang]}
      subtitle={content.subtitle}
    >
      {!isDesktopResolution && (
        <Swiper
          // install Swiper modules
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          slidesPerGroup={1}
          autoplay={{ delay: 5000 }}
          pagination={{ enabled: true, clickable: true }}
          breakpoints={{
            767: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              pagination: {
                enabled: true,
              },
            },
          }}
          css={{ marginTop: '3rem' }}
          style={{
            '--swiper-pagination-color': '#0077BE',
          }}
        >
          {updatedContent.videos.map((video, id) => (
            <SwiperSlide key={id}>
              <div css={sPlayerWrapper}>
                <ReactPlayer
                  autoPlay
                  css={sPlayer}
                  className='react-player'
                  url={video}
                  loop
                  playsinline

                  config={{
                    youtube: {
                      playerVars: {
                        controls: 1,
                        showinfo: 0,
                        modestbranding: 1,
                        rel: 0,
                        frameborder: 0,
                        // disablekb: 1,
                      },
                    },
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isDesktopResolution && (
        <Video video={content.video} videos={content.videos} />
      )}
    </Main>
  )
}

const sPlayerWrapper = {
  background: `linear-gradient(to bottom right, ${colors.brandLighter.concat(
    alpha[16]
  )}, ${colors.brandDark.concat(alpha[16])})`,
  width: '100%',
  height: 0,
  paddingBottom: '56.25%',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  transform: 'translateZ(0)',
  backdropVisibility: 'hidden',
  position: 'relative',

  [s.sm_down]: {
    borderRadius: '1rem',
    marginBottom: '1rem',
  },
}

const sPlayer = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100% !important',
  height: '100%  !important' ,
}

export default VideosSection
