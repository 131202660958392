import React from 'react'
import { useLangContext } from '../../context/lang.context'
import {
  features,
} from '../../content/kariera'
import Main from '../../components/Main'
import Features from '../../components/Features'

// const features = {
//   title: {
//     pl: 'Dlaczego warto?',
//     en: '',
//   },
//   features: [
//     {
//       title: {
//         pl: 'Konkurencyjne stawki',
//         en: '',
//       },
//       desc: {
//         pl: 'Otrzymasz wsparcie od opiekuna, który krok po kroku wprowadzi Cię w nowe obowiązki.',
//         en: '',
//       },
//       icon: 'onboarding',
//     },
//     {
//       title: {
//         pl: 'Wieloletnie doświadczenie',
//         en: '',
//       },
//       desc: {
//         pl: 'Przygotowaliśmy dla Ciebie dodatkowy system premiowy.',
//         en: 'We have prepared an additional bonus system for you.',
//       },
//       icon: 'motivation',
//     },
//     {
//       title: {
//         pl: 'Najkorzystniejszy wariant',
//         en: '',
//       },
//       desc: {
//         pl: 'Między innymi Karty Multisport i wiele więcej!',
//         en: 'Among other things, Multisport Cards and much more!',
//       },
//       icon: 'money',
//     },
//     {
//       title: {
//         pl: 'Polisa cargo',
//         en: '',
//       },
//       desc: {
//         pl: 'Możesz wybrać taką formę zatrudnienia, jaka Ci najbardziej odpowiada - wybierz umowę o pracę lub współpracę na zasadzie B2B.',
//         en: 'You can choose the form of employment that suits you best - choose a contract of employment or cooperation on a B2B basis.',
//       },
//       icon: 'employment',
//     },
//   ],
// }

const BenefitsSection = () => {
  const { lang } = useLangContext()

  return (
    <Main h={2} title={features.title[lang]}>
      <Features data={features.features} gridRepeat={3} />
    </Main>
  )
}


export default BenefitsSection