import React from 'react'
import { useLangContext } from '../../context/lang.context'
import { useStaticQuery, graphql } from 'gatsby'
import Main from '../../components/Main'
import Gallery from '../../components/Gallery'

const content = {
  title: {
    pl: 'Galeria photo',
    en: '',
  },
}

const GallerySection = () => {
  const { lang } = useLangContext()
  const query = useStaticQuery(graphql`
    {
      image1: file(absolutePath: { regex: "/images/jobs/1.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image2: file(absolutePath: { regex: "/images/jobs/2.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image3: file(absolutePath: { regex: "/images/jobs/3.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image4: file(absolutePath: { regex: "/images/jobs/4.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image5: file(absolutePath: { regex: "/images/jobs/5.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image6: file(absolutePath: { regex: "/images/jobs/6.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image7: file(absolutePath: { regex: "/images/jobs/7.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image8: file(absolutePath: { regex: "/images/jobs/8.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
      image9: file(absolutePath: { regex: "/images/jobs/9.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [WEBP, AVIF]
          )
        }
      }
    }
  `)

  const images = [
    query.image1, 
    query.image2, 
    query.image3, 
    query.image4, 
    query.image5, 
    query.image6,
    query.image7,
    query.image8,
    query.image9
  ]

  return (
    <Main h={2} title={content.title[lang]}>
      {images && <Gallery images={images} inner />}
    </Main>
  )
}

export default GallerySection
