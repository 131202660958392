import React from 'react'
import { useLangContext } from '../../context/lang.context'
import { useMatchMedia } from '../../utils/useMatchMedia'
import { Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { s} from '../../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import Main from '../../components/Main'

const content = {
  title: {
    pl: 'Nasze osiągnięcia',
    en: '',
  },
}

const AchievementsSection = () => {
  const { lang } = useLangContext()
  const isDesktopResolution = useMatchMedia('(min-width:1200px)', true)
  const query = useStaticQuery(graphql`
    {
      image1: file(absolutePath: { regex: "/images/jobs/forbes_2023.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image2: file(absolutePath: { regex: "/images/jobs/gptw_2023pl.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image3: file(absolutePath: { regex: "/images/jobs/gryf_2022.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image4: file(absolutePath: { regex: "/images/jobs/forbes_2019.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image5: file(absolutePath: { regex: "/images/jobs/gazele_biznesu.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image6: file(absolutePath: { regex: "/images/jobs/gryf_2017.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
    }
  `)

  const images = [
    { image: query.image1.childImageSharp },
    { image: query.image2.childImageSharp },
    { image: query.image3.childImageSharp },
    { image: query.image4.childImageSharp },
    { image: query.image5.childImageSharp },
    { image: query.image6.childImageSharp },
  ]

  return (
    <Main h={2} title={content.title[lang]}>
        <Swiper
          // install Swiper modules
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          slidesPerGroup={1}
          autoplay={{ delay: 5000 }}
          pagination={{ enabled: true, clickable: true }}
          breakpoints={{
            600: {
              slidesPerView: 3,
              slidesPerGroup: 1,
              pagination: {
                enabled: true,
              },
            },
            1199: {
              slidesPerView: 4,
              slidesPerGroup: 1,
              pagination: {
                enabled: true,
              },
            },
          }}
          css={{ marginTop: '3rem' }}
          style={{
            '--swiper-pagination-color': '#0077BE',
          }}
        >
          {images.map(({ image }, id) => (
            <SwiperSlide key={id}>
              <div key={id}>
                <GatsbyImage image={getImage(image)} css={sImage} alt='' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
    </Main>
  )
}

const sGalleryGrid = {
  paddingTop: '1rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '50px 40px',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '50px',
  },
  [s.xs]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '40px',
  },
}

const sImage = {
  maxWidth: '280px',
  margin: '0 auto',
}

// const sImageWrapper = {
//   position: 'relative',
//   width: '100%',
//   height: 0,
//   paddingBottom: '56.25%',
//   overflow: 'hidden',
//   borderRadius: '1rem',
// }

export default AchievementsSection
